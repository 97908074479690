@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this to your global CSS file (e.g., styles.css or tailwind.css) */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    border-top-color: #f06424;
    animation: spin 1s linear infinite;
}
