.interactive-map-container {
    width: 90vw;
    height: 90vh;
    margin: 5vh auto;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.map-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 20px;
}

.export-button {
    background: #f0ad4e;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 1em;
}

.export-button:hover {
    background-color: #ec971f;
}

.prize-legend {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 0.9em;
}

.prize-legend .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}

.map-content {
    max-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.map-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.merchandise-section {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #f0f8ff;
}

.bento-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.bento-item {
    background-color: #e8f5e9;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-name {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1rem;
}

.position-select {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.current-placement {
    font-size: 0.9rem;
    color: #4a4a4a;
}

@media (max-width: 768px) {
    .interactive-map-container {
        width: 95vw;
        height: 95vh;
        margin: 2.5vh auto;
    }

    .bento-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}